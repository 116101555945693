<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <div class="protocol-content" style="padding-top: 0px;">

            <p class="content-title">本隐私政策将帮助你了解：</p>
            <p class="text-first">（1）为保障产品的正常运行，实现个性化图文、音视频推荐、信息发布、互动交流、搜索查询等核心功能及其他功能，我们会收集您的部分必要信息；</p>
            <p class="text-first">
                （2）在您进行信息发布、互动交流、注册认证时，基于法律要求或实现功能所必需，我们可能会收集您的姓名、身份证号码、联络方式、照片、音视频文件等个人敏感信息。您有权拒绝提供这些信息，或者撤回授权同意。请您知悉，拒绝或撤回授权同意，将导致您无法使用相关的特定功能，但不影响您使用“UPUPOO”的其他功能；
            </p>
            <p class="text-first">
                （3）我们会将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内，不会将上述信息传输至境外。我们仅会为您提供“UPUPOO”软件及相关服务所必需的期间内保留您的个人信息；
            </p>
            <p class="text-first">（4）我们不会向第三方共享、提供、转让或者从第三方获取您的个人信息，除非经过您的同意；</p>
            <p class="text-first">（5）我们将努力采取合理的安全措施来保护您的个人信息。通过采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全。</p>
            <p class="text-first">（6）“UPUPOO”会基于算法等人工智能决策机制，推送您可能感兴趣的图文、音视频等信息；</p>
            <p class="text-first">（7）您在使用“UPUPOO”时，我们会基于偏好、兴趣等方面向您推送程序化广告；</p>
            <p class="text-first">（8）您访问、更正、删除个人信息与撤回同意授权的方式，以及注销账号、投诉举报的方式。</p>
            <p class="content-title text-first">如您不同意本隐私政策则不能使用该APP。</p>
            <p class="text-first">
                我们非常重视用户个人信息的保护，并且将以勤勉和审慎的义务对待这些信息。您在下载、安装、开启、浏览、注册、登录、使用（以下统称“使用”）“UPUPOO”软件及相关服务时，我们将按照本《隐私政策》收集、保存、使用、共享、披露及保护您的个人信息。我们希望通过本《隐私政策》向您介绍我们对您个人信息的处理方式，因此我们建议您认真完整地阅读本《隐私政策》的所有条款。
            </p>
            <p class="text-first">其中，与您信息及权利相关的内容将以加粗的形式提示您注意，请您重点阅读。</p>
            <p class="content-title text-first">本隐私政策旨在帮助您了解以下内容：</p>
            <p class="text-first">一、我们如何收集和使用个人信息</p>
            <p class="text-first">二、我们对Cookie和同类技术的使用</p>
            <p class="text-first">三、我们如何存储个人信息</p>
            <p class="text-first">四、我们如何共享、转让、公开披露个人信息</p>
            <p class="text-first">五、我们如何保护个人信息的安全</p>
            <p class="text-first">六、您的权利</p>
            <p class="text-first">七、未成年人使用条款</p>
            <p class="text-first">八、隐私政策的修订和通知</p>
            <p class="text-first">九、适用范围</p>
            <p class="text-first">十、其他</p>
            <p class="content-title text-first">一、我们如何收集和使用个人信息</p>
            <p class="text-first">在您使用“UPUPOO”软件及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或请您提供的信息将用于：</p>
            <p class="text-first">1.保障产品的基础正常运行；</p>
            <p class="text-first">2.实现各项功能和服务；</p>
            <p class="text-first">3.优化、改善产品和服务；</p>
            <p class="text-first">4.保障产品、服务以及用户使用安全；</p>
            <p class="text-first">5.遵循法律法规与国家标准的规定。</p>
            <p class="text-first">（一）我们直接收集与使用的个人信息</p>
            <p class="text-first">我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
            <p class="text-first">1.保障“UPUPOO”和服务的正常运行</p>
            <p class="text-first">
                当您使用“UPUPOO”及相关服务时，为了保障软件与服务的正常运行，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码（IMEI）、网络设备硬件地址（MAC）、IP地址、软件版本号、网络接入方式及类型、操作日志等信息。请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。
            </p>
            <p class="text-first">2.注册、认证、登录“UPUPOO”和相关服务</p>
            <p class="text-first">
                （1）当您注册、登录“UPUPOO”及相关服务时，您可以通过手机号创建账号，我们将通过发送短信验证码来验证您的身份是否有效，并且您可完善相关的网络身份识别信息（如头像、昵称和密码），收集这些信息是为了帮助您完成注册。您还可根据自身需求选择填写性别、地区及介绍等信息完善您的个人信息。
            </p>
            <p class="text-first">
                （2）您也可以使用第三方账号（如微信、新浪微博等）登录进入UPUPOO，您此时将授权我们获取您在第三方平台注册的公开信息（头像、昵称等），并在您同意本隐私政策后将您的第三方账号与您的UPUPOO账户绑定，使您可以通过第三方账号直接登录并使用本产品和相关服务。
            </p>
            <p class="text-first">3.为您推荐个性化的图文、音视频信息或服务个性化推荐是'UPUPOO'核心的功能之一。为实现这一功能，我们可能会收集并使用下列信息：</p>
            <p class="text-first">（1）关注、收藏、搜索、浏览偏好（例如：您感兴趣的图文、音视频信息）等您的操作、使用行为信息；</p>
            <p class="text-first">（2）反馈、发布、点赞、评论等您主动提供的信息；</p>
            <p class="text-first">拒绝提供上述信息仅会使您无法使用上述功能，但不影响“UPUPOO”其他功能与服务的正常使用。</p>
            <p class="text-first">
                我们可能会将上述信息与来自我们其他服务的信息结合，进行综合统计并通过算法做特征与偏好分析，用以向您进行个性化推荐、展示或推送您可能感兴趣的特定音视频等信息，或者推送更合适您的特定功能或服务：</p>
            <p class="text-first">（1）向您展示、推荐或推送与您有更高相关程度的图文、音视频；</p>
            <p class="text-first">（2）推荐与您相关程度更高的程序化广告。</p>
            <p class="text-first">请您了解，我们收集、使用上述信息时进行了去标识化处理，数据分析仅对应特定的、无法直接关联用户身份的编码，无法也绝不会与您的真实身份相关联。</p>
            <p class="text-first">4.为您提供图文、音视频信息发布功能或服务</p>
            <p class="text-first">您主动发布图文、音视频、评论时，我们将收集图文、音视频等信息，并展示您的昵称、头像、发布信息内容等。</p>
            <p class="text-first">您发布图文、音视频时，我们会请求您授权相机、照片等敏感权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用UPUPOO的其他功能。</p>
            <p class="content-title text-first">UPUPOO权限说明：</p>
            <p class="content-title text-first">1. 访问网络权限：为保障软件的正常运行</p>
            <p class="content-title text-first">2. 改变网络状态权限：保障用户正常下载壁纸</p>
            <p class="content-title text-first">3. 读取系统日志权限：为了收集可能因为错误，应用强制关闭时的Log日志</p>
            <p class="content-title text-first">4. 开机允许权限：为保障壁纸在重新开机后仍是使用状态</p>
            <p class="content-title text-first">5. 获取wifi状态权限：检查网络状态是否正常，保证程序正常运行</p>
            <p class="content-title text-first">6. 设置壁纸权限：为便捷用户操作，获取此权限可让用户在软件内设置壁纸</p>
            <p class="content-title text-first">7. 获取网络状态权限：检查网络状态是否正常，保证程序正常运行</p>
            <p class="content-title text-first">8. 读取联系人权限：当用户使用来电秀功能时为保证功能正常运作需要获取联系人姓名等信息</p>
            <p class="content-title text-first">9. 获取系统设置权限：当用户使用来电秀功能时需要获取此权限将来电界面更换为用户设置的样式</p>
            <p class="content-title text-first">1 使用手机震动权限：当用户使用闪电壁纸时为保证震动效果，需要获取此权限</p>
            <p class="content-title text-first">11. 接打电话权限：当用户使用来电秀功能时需要获取此权限试用户正常接听</p>
            <p class="content-title text-first">12. 读取内存卡权限：满足用户正常使用软件的上传、下载功能</p>
            <p class="content-title text-first">13. 相机权限：当用户使用透明壁纸时将需要开启此权限</p>
            <p class="content-title text-first">14. 开启自启动权限：为了壁纸能开机时就能在桌面运行</p>
            <p class="content-title text-first">15. 安装未知来源权限：下载第三方广告内软件时需要获取该权限</p>
            <p class="content-title text-first">16. 修改声音权限：用户使用软件的过程中可在程序内部控制壁纸的声音开启及大小</p>
            <p class="content-title text-first">17. 获取手机状态权限：为用户正常使用来电秀功能</p>
            <p class="content-title text-first">18. 系统悬浮框权限：当用户使用来电秀功能时为保证该功能正常运行需要获取此权限</p>
            <p class="content-title text-first">19. 定位权限：用作数据统计，生成用户画像</p>
            <p class="content-title text-first">2 锁屏权限：当用户使用锁屏壁纸时需要获取此权限</p>
            <p class="text-first">5.为您提供互动功能或服务</p>
            <p class="text-first">您主动关注您感兴趣的账号并与其他账号进行互动，进行评论或分享内容时，我们会收集您关注的账号，并向您展示您关注账号发布内容。</p>
            <p class="text-first">6.为您提供搜索服务</p>
            <p class="text-first">
                您使用UPUPOO的搜索服务时，我们会收集您的搜索关键字信息、日志记录等。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。
            </p>
            <p class="text-first">7.开展营销活动</p>
            <p class="text-first">
                当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息。这些信息中可能包含个人敏感信息（如个人电话号码、银行账号等），是您收到转账或者礼品所必要的，如果您拒绝提供这些信息，我们将可能无法向您转账或发放礼品。
            </p>
            <p class="text-first">8.保障产品、服务及用户使用安全</p>
            <p class="text-first">
                为帮助我们更好地了解“UPUPOO”及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。</p>
            <p class="text-first">（二）我们可能从第三方获得的您的个人信息</p>
            <p class="text-first">1.当您主动使用第三方账号登录“UPUPOO”时，我们会收集第三方账号的昵称、头像等信息。</p>
            <p class="text-first">2.用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分信息（如：在评论、留言、发布图文、音视频中涉及到与您相关的信息）。</p>
            <p class="text-first">如果您不提供这些信息，您将可能无法获得相关服务</p>
            <p class="text-first">（三）收集、使用个人信息目的变更的处理</p>
            <p class="text-first">
                请您了解，随着我们业务的发展，可能会对“UPUPOO”的功能和提供的服务有所调整变化。当新功能或服务与个性化图文、音视频推荐、程序化广告推送、发布信息、用户推荐、互动交流、搜索查询、注册认证等场景相关时，收集与使用的个人信息属于与原目的具有直接或合理关联。在与原目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。
            </p>
            <p class="text-first">（四）依法豁免征得同意收集和使用的个人信息</p>
            <p class="text-first">请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意</p>
            <p class="text-first">（1）与国家安全、国防安全直接相关的；</p>
            <p class="text-first">（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p class="text-first">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p class="text-first">（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p class="text-first">（5）所收集的您的个人信息是您自行向社会公众公开的；</p>
            <p class="text-first">（6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p class="text-first">（7）根据您的要求签订或履行合同所必需的；</p>
            <p class="text-first">（8）用于维护“UPUPOO”软件及相关服务的安全稳定运行所必需的，例如发现、处置“UPUPOO”软件及相关服务的故障；</p>
            <p class="text-first">（9）为合法的新闻报道所必需的；</p>
            <p class="text-first">（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
            <p class="text-first">（11）法律法规规定的其他情形。</p>
            <p class="text-first">
                特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，则在结合使用期间，这些信息将作为您的个人信息按照本隐私政策处理与保护。
            </p>
            <p class="content-title text-first">二、对Cookie和同类技术的使用</p>
            <p class="text-first">
                Cookie和同类技术是互联网中的通用常用技术。当您使用“UPUPOO”软件及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集和存储您访问、使用本产品时的信息。我们使用Cookie和同类技术主要为了实现以下功能或服务：
            </p>
            <p class="text-first">（一）保障产品与服务的安全、高效运转</p>
            <p class="text-first">我们可能会设置认证与保障安全性的cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
            </p>
            <p class="text-first">（二）帮助您获得更轻松的访问体验</p>
            <p class="text-first">使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（例如：实现一键登录流程、记录搜索历史）。</p>
            <p class="text-first">（三）为您推荐、展示、推送您可能感兴趣的内容或账号</p>
            <p class="text-first">
                我们可能会利用Cookie和同类技术（例如Beacon、Proxy等技术）了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验，并优化您对广告的选择。我们承诺，我们不会将Cookie用于本隐私政策所述目的之外的任何其他用途。
            </p>
            <p class="content-title text-first">三、我们如何存储个人信息</p>
            <p class="text-first">（一）信息存储的地点</p>
            <p class="text-first">
                我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循国家相关规定，征求您的同意。
            </p>
            <p class="text-first">（二）存储期限</p>
            <p class="text-first">
                我们仅在为提供“UPUPOO”及服务之目的所必需的期间内保留您的个人信息，例如，您发布的图文、音视频等信息、评论、点赞等信息，在您未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
            </p>
            <p class="content-title text-first">四、我们如何共享、转让、公开披露个人信息</p>
            <p class="text-first">（一）个人信息的共享、转让</p>
            <p class="text-first">我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</p>
            <p class="text-first">1.在下列情况下，我们可能会共享的个人信息</p>
            <p class="text-first">（1）为实现程序化广告推送，我们可能会与广告合作伙伴共享去标识化或匿名化处理后的信息</p>
            <p class="text-first">
                我们可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会分享用于识别您个人身份的信息，例如您的姓名、身份证号等，仅会向这些合作伙伴提供难以识别您个人身份的用户画像标签及去标识化或匿名化后的统计类信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。
            </p>
            <p class="text-first">（2）为实现特定功能而与业务合作伙伴共享</p>
            <p class="text-first">
                当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为您提供服务时，例如您需要使用地理位置功能时，为实现这一功能，我们可能会收集您的位置信息及相关设备信息（例如硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。您可以拒绝提供此类信息，但不影响您使用其他服务。
            </p>
            <p class="text-first">（3）帮助您参加营销推广活动</p>
            <p class="text-first">
                当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息，以便第三方能及时向您提供奖品。当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息。经过您的明示同意，我们会将上述信息与第三方共享，以便我们能委托第三方及时向您提供奖品。
            </p>
            <p class="text-first">（4）为实现特定功能和优化服务合作涉及的信息共享第三方名单表（后续如有调整将实时更新）</p>
            <table class="table_p">
                <tbody>
                    <tr>
                        <th class="th_1">第 三 方</th>
                        <th class="th_2">类 型</th>
                        <th class="th_3">目 的</th>
                        <th class="th_4">方 式</th>
                    </tr>
                    <tr>
                        <td>微信支付</td>
                        <td rowspan="2">支 付</td>
                        <td rowspan="2">购买广告消除计划等虚拟产品或服务</td>
                        <td>收集微信openID </td>
                    </tr>
                    <tr>
                        <td>支付宝支付</td>
                        <td>收集支付宝userID</td>
                    </tr>
                    <tr>
                        <td>微信登录</td>
                        <td rowspan="2">登 录</td>
                        <td rowspan="2">第三方登录</td>
                        <td>获取微信昵称、头像等 </td>
                    </tr>
                    <tr>
                        <td>QQ登录</td>
                        <td>获取QQ昵称、头像等 </td>
                    </tr>
                    <tr>
                        <td>微信分享</td>
                        <td rowspan="3">分 享</td>
                        <td rowspan="3">壁纸分享、动态分享等</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>QQ分享</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>新浪微博分享</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>友盟</td>
                        <td rowspan="2">统 计</td>
                        <td rowspan="2">用于用户行为数据的点击</td>
                        <td>我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</td>
                    </tr>
                    <tr>
                        <td>华为统计</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>极光</td>
                        <td>推 送</td>
                        <td>用于推送app相关的内容</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>百度广告</td>
                        <td rowspan="3">广 告</td>
                        <td rowspan="3">app开屏、下载壁纸、使用部分组件等时弹出广告</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>腾讯广告</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>穿山甲广告</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>阿里云oss上传</td>
                        <td>上 传</td>
                        <td>上传壁纸</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <p class="text-first">2.对共享个人信息第三方主体的谨慎评估及责任约束</p>
            <p class="text-first">（1）经您同意，我们只会与第三方共享实现目的所必要的信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的同意。</p>
            <p class="text-first">
                （2）对我们与之共享您个人信息的第三方，该些第三方会与我们签订保密协议。同时，我们会对其数据安全能力与环境进行评估并要求第三方以不低于本隐私政策所要求的保密和安全措施来处理该些信息。
            </p>
            <p class="text-first">3.收购、兼并、重组时个人信息的转让</p>
            <p class="text-first">
                随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所要求的安全标准继续保护或要求个人信息的继受方继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。
            </p>
            <p class="text-first">（二）个人信息的公开披露</p>
            <p class="text-first">
                除对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息外，我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。</p>
            <p class="text-first">（三）依法豁免征得同意共享、转让、公开披露的个人信息</p>
            <p class="text-first">请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意</p>
            <p class="text-first">（1）与国家安全、国防安全直接相关的；</p>
            <p class="text-first">（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p class="text-first">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p class="text-first">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您的同意的；</p>
            <p class="text-first">（5）您自行向社会公众公开的个人信息；</p>
            <p class="text-first">（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            <p class="content-title text-first">五、我们如何保护个人信息安全</p>
            <p class="text-first">
                （一）我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。</p>
            <p class="text-first">（二）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。</p>
            <p class="text-first">（三）我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人
                信息，并适时对数据和技术进行安全审计。
            </p>
            <p class="text-first">
                （四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
            </p>
            <p class="text-first">
                （五）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
            </p>
            <p class="text-first">
                （六）我们谨此特别提醒您，本隐私政策提供的个人信息保护措施仅适用于“UPUPOO”软件及相关服务。一旦您离开“UPUPOO”及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在“UPUPOO”软件及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于“UPUPOO”的链接或引导。
            </p>
            <p class="content-title text-first">六、您的权利</p>
            <p class="text-first">我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的访问、修改（更新或更正）、删除以及撤回授权同意的权利，以使您有能力保障您的隐私和安全。</p>
            <p class="text-first">（一）访问、更正、删除您的个人信息&nbsp;</p>
            <p class="text-first">您可以通过以下方式管理您的信息：</p>
            <p class="text-first">当您完成“UPUPOO”的账号注册、登录并进行合理和必要的身份验证后，您可以查阅、修改、删除您提交给“UPUPOO”软件及相关服务的个人信息（包括删除您已经发布的评论、视频等）。
            </p>
            <p class="text-first">一般情况下，您可以在客户端应用程序中点击右下方【我的】-【作品】列表对您发布过的作品进行删除。或是，在【我的】-【设置】-【账号安全】中对注册登录时绑定的资料进行修改。</p>
            <p class="text-first">（二）管理、撤回授权您的信息</p>
            <p class="text-first">您可以通过在设备本身操作系统中关闭设备功能，改变您在授权公司继续收集个人信息的范围或撤回您的授权。</p>
            <p class="text-first">
                请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，公司无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响公司此前基于您的授权而开展的个人信息处理。
            </p>
            <p class="text-first">（三）访问隐私政策&nbsp;</p>
            <p class="text-first">您可以【设置】—【关于UPUPUOO】—【隐私政策】查看本隐私政策的全部内容。</p>
            <p class="text-first">（四）停止运营向您告知权利</p>
            <p class="text-first">如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。</p>
            <p class="content-title text-first">七、未成年人条款</p>
            <p class="text-first">若您是未满18周岁的未成年人，在使用“UPUPOO”软件及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。</p>
            <p class="text-first">
                我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
            </p>
            <p class="text-first">若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。</p>
            <p class="content-title text-first">八、隐私政策的修订和通知</p>
            <p class="text-first">
                （一）为了给您提供更好的服务，“UPUPOO”软件及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，该等修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。但未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。
            </p>
            <p class="text-first">（二）本隐私政策更新后，我们会在“UPUPOO”应用程序客户端和官方网站发出更新版本，并在更新后的条款生效前以适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
            </p>
            <p class="text-first">如您继续使用我们的服务，表示同意接受修订后的本政策的内容，但是如果更新的内容需要采集照片、音视频、获取您通讯录、联系方式、地理位置等个人敏感信息，仍会再次以显著方式征求您的同意。
            </p>
            <p class="text-first">（三）对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</p>
            <p class="text-first">本隐私政策所指的重大变更包括但不限于：</p>
            <p class="text-first">1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</p>
            <p class="text-first">2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有变更等；</p>
            <p class="text-first">3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p class="text-first">4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <p class="text-first">5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。</p>
            <p class="content-title text-first">九、适用范围</p>
            <p class="text-first">本隐私权政策适用于由【武汉小咪网络科技有限公司】及其关联方提供的所有服务，包括UPUPOO客户端、网站等，不适用于有单独的隐私权政策且未纳入本隐私权政策的第三方产品或服务。</p>
            <p class="text-first">本隐私权政策不适用于：</p>
            <p class="text-first">1.其他第三方产品或服务，可能包括在个性化推荐中向您显示的产品或网站和广告内容或者UPUPOO服务中链接到的其他产品或网站；</p>
            <p class="text-first">2.为UPUPOO服务进行广告宣传的其他第三方。</p>
            <p class="text-first">
                您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请您仔细阅读第三方的条款。请您妥善保护自己的个人信息，仅在必要的情况下向第三方提供。
            </p>
            <p class="text-first">
                本隐私政策中所述的“UPUPOO”及相关服务有可能会根据您所使用的设备型号、系统版本、软件应用程序版本等因素而有所不同。最终的产品和服务以您所使用的“UPUPOO”软件及相关服务为准。
            </p>
            <p class="text-first">如对本隐私政策内容有任何疑问、意见或建议，您可通过登录“UPUPOO”客户端内的“意见反馈”页面与我们联系。</p>
            <p class="content-title text-first">十、其他</p>
            <p class="text-first">（一）本“隐私政策”中的标题仅为方便及阅读而设，并不影响本《隐私政策》中任何规定的含义或解释。</p>
            <p class="text-first">（二）本“隐私政策”的版权为我们所有，在法律允许的范围内，我们拥有解释和修改的权利。</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.table_p {
  table-layout: fixed;
  empty-cells: show;
  border-collapse: collapse;
  margin: 12px auto;
  font-size: 24px;
}

.th_1 {
  width: 25%;
}

.th_2 {
  width: 10%;
}

.th_3 {
  width: 45%;
}

.th_4 {
  width: 20%;
}

td,
th {
  border: 1px solid #cad9ea;
  text-align: center;
}

span {
  display: inline-block;
}

.content-title {
  font-size: 24px !important ;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
  line-height: 48px;
  margin-top: 32px;
}

.text-first {
  text-indent: 4px;
  margin-top: 32px;
}

.span-title {
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
  display: inline !important;
}

.p-title {
  font-weight: bold;
  color: rgba(29, 29, 29, 1);
}

.no-indext {
  text-indent: 0px !important;
}

.p-behind {
  display: inline !important;
}

.text-p {
  text-indent: 4px;
  margin-top: 32px;
}

.protocol {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
}

.protocol-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  border-top: 01px solid #d8d8d8;
  font-size: 28px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #5f5f5f;
  padding: 0 3px;
  line-height: 42px;
  text-align: left;

  padding-bottom: 1px;
  overflow-y: auto;
}
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
